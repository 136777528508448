<template>
    <div>
        <div id="step-1-content">
        <div class="m-0 dark-blue-text font-weight-bold title-font">
            お部屋の画像をアップロードしてください
        </div>
        <p class="grey-text">
            家具消しを行う画像をアップロードしてください。家具消し写真もできるだけ広角での撮影をお勧めします。
        </p>
        <b-alert
            class="m-0 mt-3 mb-3 light-grey-outline grey-text"
            variant="warning"
            v-if="formMessage !== null && staging.items.length === 0"
            show
        >
            <p class="m-0 font-weight-bold">次の内容を確認してください。</p>
            <ul v-html="formMessage"></ul>
        </b-alert>
        <div class="uploader-wrapper">
            <input
                id="file-uploader"
                type="file"
                accept=".jpeg,.jpg,.png"
                multiple
                v-on:change="validatePhotos"
                hidden
            />
            <div
                class="initial-state"
                v-if="staging.items.length === 0 && loaderItems.length === 0"
            >
            <div class="img-upload-box">
                <div class="main-container">
                <font-awesome-icon
                    class="mb-3 dark-blue-text"
                    :icon="{ prefix: 'fas', iconName: 'upload' }"
                    style="font-size: 60px"
                />
                <div class="img-font">お部屋の画像をアップロードしてください</div>
                <button
                    class="btn btn-ds mt-3 white dark-blue-outline dark-blue-text"
                    v-waves.light
                    v-on:click="setAddPhoto"
                    :disabled="
                    uploadCount > 0 || loaderItems.length > 0 ? true : false
                    "
                >
                    <div v-if="uploadCount > 0 || loaderItems.length > 0">
                    <b-spinner style="height: 18px; width: 18px"></b-spinner>
                    <span class="ml-3">アップロード中 ({{ uploadCount }})</span>
                    </div>
                    <span v-else class="img-btn">画像アップロード</span>
                </button>
                </div>
            </div>
            </div>
            <div
            class="photo-list-state container"
            v-if="staging.items.length > 0 || loaderItems.length > 0"
            >
            <div class="row">
                <div class="col-12">
                <button
                    class="btn btn-ds mt-3 white dark-blue-outline dark-blue-text"
                    v-waves.light
                    v-on:click="setAddPhoto"
                    style="max-width: 405px"
                    :disabled="
                    uploadCount > 0 || loaderItems.length > 0 ? true : false
                    "
                >
                    <div v-if="uploadCount > 0 || loaderItems.length > 0">
                    <b-spinner style="height: 18px; width: 18px"></b-spinner>
                    <span class="ml-3">アップロード中 ({{ uploadCount }})</span>
                    </div>
                    <span v-else>画像を追加する</span>
                </button>
                </div>
                <div class="row col-12 p-0 m-0 mt-5">
                <div class="col-6 text-left" style="height: 38px">
                    <div class="m-0 mt-1 dark-blue-text ">
                    依頼枚数 {{ staging.items.length }}/20
                    </div>
                </div>
                <div class="col-6 text-right" v-if="canDelete">
                    <button
                    class="btn dark-blue font-weight-bold white-text grey-hover border-radius-4"
                    v-waves.light
                    v-on:click="setRemovePhotoModal"
                    :disabled="photoToBeDeleted.length > 0 ? false : true"
                    >
                    <font-awesome-icon
                        :icon="{ prefix: 'fas', iconName: 'trash' }"
                    />
                    </button>
                    <button
                    class="btn dark-blue white-text grey-hover border-radius-1 ml-2"
                    v-waves.light
                    v-on:click="setDelete"
                    >
                    選択をキャンセル
                    </button>
                </div>
                <div class="col-6 text-right" v-else>
                    <button
                    class="btn dark-blue white-text grey-hover border-radius-1 ml-2"
                    v-waves.light
                    v-on:click="setDelete"
                    >
                    画像を削除する
                    </button>
                </div>
                </div>
                <div class="col-12">
                <b-alert
                    class="m-0 mt-3 mb-3 light-grey-outline grey-text"
                    variant="warning"
                    v-if="formMessage !== null"
                    show
                >
                    <p class="m-0 font-weight-bold">
                    Please check the following errors:
                    </p>
                    <ul v-html="formMessage"></ul>
                </b-alert>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                <b-form-checkbox
                    class="d-inline-block mr-2"
                    v-model="photoSelectStatus"
                    v-on:input="setPhotoSelectStatus"
                    v-if="canDelete"
                >
                    <p class="grey-text" style="margin: 2px 0 0 0">
                    {{ photoSelectStatus ? 'すべて非選択' : 'すべて選択' }}
                    </p>
                </b-form-checkbox>
                </div>
            </div>
            <b-form-checkbox-group v-model="photoToBeDeleted">
                <div id="photo-list" class="row mt-3">
                <div
                    class="col-6 col-md-4 col-lg-4 col-xl-4 mb-3"
                    v-for="(el, i) in staging.items"
                    :key="i"
                >
                    <div class="photo-item">
                    <b-skeleton-img></b-skeleton-img>
                    <p
                        style="
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        font-weight: bold;
                        left: 50%;
                        top: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        "
                    >
                        Loading Image ...
                    </p>
                    <img
                        :src="el.file_url"
                        @click="openModal(el.file_url)"
                        onerror="this.src='/img/image-error.png'"
                    />
                    <b-form-checkbox
                        style="accent-color:white"
                        name="remove-phohoto"
                        class="item-image"
                        :value="el.id"
                        v-if="canDelete"
                    ></b-form-checkbox>
                    </div>
                </div>
                <div
                    class="col-6 col-md-4 col-lg-4 col-xl-4 mb-3"
                    v-for="(el, i) in loaderItems"
                    :key="'loader_' + i"
                >
                    <div class="photo-item-loader">
                    <div
                        class="b-aspect d-flex boder-radius-2"
                        style="height: inherit"
                    >
                        <div class="b-aspect-content flex-grow-1 w-100 mw-100">
                        <div
                            class="b-skeleton b-skeleton-img b-skeleton-animate-wave"
                            style="border-radius: 15px"
                        ></div>
                        </div>
                    </div>
                    <p
                        style="
                            position: absolute;
                            width: 100%;
                            text-align: center;
                            font-weight: bold;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        "
                    >
                        アップロード中
                    </p>
                    </div>
                </div>
                </div>
            </b-form-checkbox-group>
            </div>
        </div>
        </div>

        <b-modal
        id="step1-upload-alert-modal"
        class="border-radius-2"
        size="lg"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
        >
        <h5
            class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
            v-on:click="updateUploadAlertModalState"
        >
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </h5>
        <div class="container text-center">
            <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
            <p class="mt-4 mb-0 dark-blue-text font-weight-bold text-size">
            拡大された画像がアップロードされますと、
            </p>
            <p class="mb-0 dark-blue-text font-weight-bold text-size">
            そのままの形でステージングが行われますのでご注意ください。
            </p>
            <div class="img-area">
            <div class="img1">
                <img
                class="shadow-1 border-radius-1 mb-4"
                src="/img/staging/upload-modal-model-2.png"
                alt="upload-modal-model-1"
                />
            </div>

            <div class="img2">
                <img
                class="shadow-1 border-radius-1"
                src="/img/staging/upload-modal-model-1.png"
                alt="upload-modal-model-1"
                />
            </div>
            </div>
            <div class="row mb-3 mt-1">
            <div class="col-12 mb-2">
                <button
                class="btn btn-ds dark-blue white-text pt-2 pb-2"
                type="button"
                v-waves.light
                v-on:click="updateUploadAlertModalState"
                style="width: 455px; height: 50px; max-width: 100%"
                >
                OK
                </button>
            </div>
            </div>
        </div>
        </b-modal>

        <b-modal
        id="step1-remove-photo"
        class="border-radius-2"
        size="md"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
        >
        <h5
            class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
            v-on:click="$bvModal.hide('step1-remove-photo')"
        >
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </h5>
        <div class="container text-center">
            <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
            <div class="mt-4 mb-0 dark-blue-text del-font">
            この写真を本当に削除してもよろしいですか？
            </div>
            <div class="row mb-3 mt-5">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <button
                class="btn btn-ds light-grey white-text pt-2 pb-2 del-select"
                type="button"
                v-waves.light
                v-on:click="$bvModal.hide('step1-remove-photo')"
                >
                <span>いいえ</span>
                </button>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <button
                class="btn btn-ds dark-blue white-text pt-2 pb-2 del-select"
                type="button"
                v-waves.light
                v-on:click="setRemovePhoto"
                >
                はい
                </button>
            </div>
            </div>
        </div>
        </b-modal>
        <image-modal @closeModal="closeModal()" v-if="isOpenModal">
            <img :src="modalImageUrl">
        </image-modal>
    </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import {
    cus_stagingService_GetAllSRItems,
    cus_stagingService_UpdateSRAddItems,
    cus_stagingService_DeleteSRItems,
    cus_stagingService_UpdateSRStyleID,
} from '../../services/customer/staging';

// Import Modal
import ImageModal from '../../components/customer/Image-Modal.vue';

export default {
    metaInfo() {
        return {
            title: 'Digital Staging - Staging | Delete New Request',
        };
    },

    data() {
        return {
            loaderItems: [],
            uploadCount: 0,
            photoSelectStatus: false,
            photoToBeDeleted: [],
            formMessage: null,
            canDelete: false,
            isOpenModal: false,
            modalImageUrl: null,
        };
    },

    computed: {
        ...mapGetters(['staging']),
    },
    components: {
        'image-modal': ImageModal,
    },
    updated() {
        this.$parent.scrollTop();
    },

    created() {
        this.setStepProceed();
    },

    mounted() {
        this.setHideUploadAlertModal();
        this.getDraftImens();
        this.$parent.scrollTop();
        console.log('test2');
        console.log(this.loaderItems);
        console.log(this.uploadCount);
    },
    methods: {
        updateSrData() {
            let _this = this;
            _this.$parent.scrollTop();
            _this.$store.state.modalLoaderMessage = '保存中';
            _this.$bvModal.show('modal-loader');

            return cus_stagingService_UpdateSRStyleID({
                id: _this.staging.info.id,
                furnishing_style_id: null,
                request_category: 3,
                token: _this.staging.accessToken,
            })
                .then((response) => {
                let data = response.data.result;

                _this.selectedFurnishingStyle = data.furnishing_style_id;
                _this.$store.state.staging.info.furnishing_style_id =
                    data.furnishing_style_id;

                _this.$parent.steps.completed.push(1);
                _this.$parent.steps.current = 2;
                setTimeout(() => {
                    _this.$bvModal.hide('modal-loader');
                    _this.$parent.scrollTop();
                }, 500);
                })
                .catch((error) => {
                console.log(error);

                setTimeout(() => {
                    _this.$bvModal.hide('modal-loader');
                    _this.$bvToast.toast('エラーが起こりました。再度お試しください。', {
                    title: 'システムエラー',
                    autoHideDelay: 5000,
                    variant: 'danger',
                    appendToast: true,
                    });
                }, 500);
                });
            },
        openModal(imgUrl) {
            this.modalImageUrl = imgUrl;
            this.isOpenModal = true;
        },
        closeModal(){
        this.isOpenModal = false;
        },
        getDraftImens() {
        let _this = this;
        cus_stagingService_GetAllSRItems({
            id: _this.staging.info.id,
            paginated: 0,
            page_size: 0,
            page: 0,
            token: _this.staging.accessToken,
        })
            .then((_response) => {
            let stagingItems = _response.data.result;
            _this.$store.state.staging.items = stagingItems;
            })
            .catch((_error) => {
            console.log(_error);
            });
        },
        setDelete() {
        this.photoSelectStatus = false;
        this.setPhotoSelectStatus(false);
        this.canDelete = !this.canDelete;
        },
        setHideUploadAlertModal() {
        if (this.$store.state.staging.uploadAlertModal === true) {
            this.$bvModal.show('step1-upload-alert-modal');
        } else {
            this.$bvModal.hide('step1-upload-alert-modal');
        }
        },

        updateUploadAlertModalState() {
        console.log('close', this.$parent);
        this.$store.state.staging.uploadAlertModal = false;
        this.$bvModal.hide('step1-upload-alert-modal');
        },

        setStepProceed() {
            if (this.staging.items.length > 0) {
                this.$parent.steps.proceedDisabled = false;
            } else {
                this.$parent.steps.proceedDisabled = true;
            }
        },

        setAddPhoto() {
        if (this.staging.items.length < 20) {
            let fileUploader = document.querySelector('#file-uploader');

            fileUploader.value = null;
            fileUploader.click();
        } else {
            this.formMessage =
            '<li>Maximum of 20 images only. Please remove image to add a new one.</li>';
        }
        },

        validatePhotos(e) {
            let _this = this;

            _this.formMessage = null;

            let photoListCheckBox = document.querySelectorAll(
                '.custom-control-input'
            );
            photoListCheckBox.forEach((el) => {
                el.removeAttribute('checked');
            });
            _this.photoToBeDeleted = [];

            let files = e.target.files;
            let filesErrorMessage = [];

            _this.$parent.steps.proceedDisabled = true;
            _this.uploadCount = files.length;

            if (files.length > 0) {
                if (_this.staging.items.length + files.length <= 20) {
                    for (let i = 0; i < files.length; i++) {
                        if (
                        files[i].type === 'image/jpeg' ||
                        files[i].type === 'image/png'
                        ) {
                        if (files[i].size <= 10000000) {
                            console.log('Processing ...');
                        } else {
                            filesErrorMessage.push(
                            `<li>ファイル名 (${files[i].name}) - 画像は 10 MB 未満である必要があります。</li>`
                            );

                            _this.$parent.steps.proceedDisabled = false;
                            _this.uploadCount = 0;
                        }
                        } else {
                        filesErrorMessage.push(
                            `<li>File Name (${files[i].name}) - Please upload a file type of JPEG, JPG or PNG.</li>`
                        );

                        _this.$parent.steps.proceedDisabled = false;
                        _this.uploadCount = 0;
                        }
                    }

                    setTimeout(() => {
                        _this.validatePhotosProceed(_this, files, filesErrorMessage);
                    }, 1000);
                } else {
                    let toBeUploadCount = files.length;
                    let removeCount = _this.staging.items.length + files.length - 20;

                    _this.formMessage = `
                        <li>
                        Maximum of 20 images only. You just selected additional of ${
                            toBeUploadCount - removeCount
                        } images.
                        Please remove atleast ${removeCount} images from the selected ones proceed.
                        </li>
                    `;

                    _this.$parent.steps.proceedDisabled = false;
                    _this.uploadCount = 0;
                }
            }
        },
        validatePhotosProceed(_this, files, filesErrorMessage) {
            if (filesErrorMessage.length === 0) {
                for (let i = 0; i < files.length; i++) {
                _this.loaderItems.push('item-' + i);
                let formData = new FormData();
                formData.append('sr_id', _this.staging.info.id);
                formData.append('img', files[i]);

                cus_stagingService_UpdateSRAddItems(
                    {
                    token: _this.staging.accessToken,
                    },
                    formData
                )
                    .then((response) => {
                    if (response.data.result) {
                        _this.loaderItems.pop();
                        _this.staging.items.push(response.data.result);
                    }
                    _this.uploadCount -= 1;
                    if (_this.uploadCount == 0) {
                        _this.setStepProceed();
                    }
                    })
                    .catch((error) => {
                    _this.uploadCount = 0;
                    _this.formMessage = error;
                    console.log(error);
                    });
                }
            } else {
                _this.formMessage = filesErrorMessage.join('');

                _this.$parent.steps.proceedDisabled = false;
                _this.uploadCount = 0;
            }
        },
        setPhotoSelectStatus(e) {
        let photoListCheckBox = document.querySelectorAll(
            '#photo-list .custom-control-input'
        );
        if (e) {
            this.photoToBeDeleted = this.staging.items.map((_) => _.id);

            photoListCheckBox.forEach((el) => {
            el.setAttribute('checked', true);
            });
        } else {
            this.photoToBeDeleted = [];

            photoListCheckBox.forEach((el) => {
            el.removeAttribute('checked');
            });
        }
        },

        setRemovePhotoModal() {
        this.formMessage = null;

        if (this.photoToBeDeleted.length > 0) {
            this.$bvModal.show('step1-remove-photo');
        } else {
            this.formMessage = '<li>Please select photos to be deleted.</li>';
        }
        },

        setRemovePhoto() {
        let _this = this;

        _this.$bvModal.hide('step1-remove-photo');

        _this.$store.state.modalLoaderMessage = '削除中';
        _this.$bvModal.show('modal-loader');

        cus_stagingService_DeleteSRItems({
            item_ids: _this.photoToBeDeleted,
            token: _this.staging.accessToken,
        })
            .then(() => {
            _this.$store.state.staging.items = _this.staging.items.filter(
                (el) => {
                if (!_this.photoToBeDeleted.includes(el.id)) {
                    return el;
                }
                }
            );

            _this.setStepProceed();
            _this.setDelete();
            setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
            }, 500);
            })
            .catch((error) => {
            console.log(error);

            _this.$bvToast.toast(
                'アップロード中にエラーが起こりました。再度お試しください。',
                {
                title: 'システムエラー',
                autoHideDelay: 5000,
                variant: 'danger',
                appendToast: true,
                }
            );

            setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
            }, 500);
            });
        },
    },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
#step-1-content .uploader-wrapper .initial-state {
  position: relative;
  height: 300px;
  width: 100%;
}

.title-font {
  font-size:1rem;
}

.del-font {
  font-weight: 550;
  font-size: 18px;
}

.del-select {
  font-weight:normal;
  height: 50px;
}

#step-1-content .uploader-wrapper .initial-state .main-container {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 767px) {
  #step-1-content .uploader-wrapper .initial-state .main-container {
    margin: 100px 0 100px 0;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transform: none;
    transform: none;
  }
}

.img-font {
  font-weight:normal;
  font-size:14px;
}

.img-btn {
  font-size:12px;
  font-weight:normal;
}

#step-1-content .uploader-wrapper .initial-state .main-container .btn {
  max-width: 400px;
}

@media only screen and (max-width: 767px) {
  #step-1-content .uploader-wrapper .initial-state .main-container .btn {
    max-width: 100%;
  }
}

#step-1-content #photo-list .photo-item {
  position: relative;
  height: 250px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.img-upload-box {
  background-color:#E9E9E9;
  height:300px;
  width:70%;
  margin: 0 auto;
}

@media only screen and (max-width: 1199px) {
  #step-1-content #photo-list .photo-item {
    height: 200px;
  }
}

@media only screen and (max-width: 425px) {
  #step-1-content #photo-list .photo-item {
    height: 150px;
  }
}

#step-1-content #photo-list .photo-item img {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 16px;
}

#step-1-content #photo-list .photo-item .custom-checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
}

#step-1-content #photo-list .photo-item .b-aspect {
  height: 100%;
}

#step-1-content #photo-list .photo-item .b-aspect .b-skeleton-img {
  border-radius: 15px;
}
.img-area {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.text-size {
  font-size: 16px;
}
.img1 {
  margin: 0 5px;
}
.img2 {
  margin: 0 5px;
}
</style>
